import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";

const Message = ({ message }) => {
	let styleDirection = {};

	let { channel, cleanedText, text, hidden } = message;

	if (channel === 1) {
		styleDirection = {
			left: 10,
			maxWidth: "80%",
			marginRight: "20%",
			backgroundColor: "white",
			borderRadius: "15px 15px 15px 0",
			padding: 10
		};
	} else if (channel === 0) {
		styleDirection = {
			right: 10,
			maxWidth: "80%",
			marginLeft: "20%",
			backgroundColor: "#2196f3",
			color: "white",
			fontWeight: "bold",
			borderRadius: "15px 15px 0 15px",
			padding: 10
		};
	}

	if (hidden === true) {
		styleDirection.backgroundColor = "#d6d6d6";
		styleDirection.fontStyle = "italic";
		styleDirection.fontWeight = "normal";
		styleDirection.color = "inherit";
	}

	return (
		<MDBox mt={1} style={styleDirection}>
			{hidden && <MDTypography className="phoneCallMessageHiddenText">Message caché</MDTypography>}
			<p
				style={{
					fontSize: 12,
					whiteSpace: "pre-wrap"
				}}
			>
				{cleanedText || text}
			</p>
		</MDBox>
	);
};

export default Message;
