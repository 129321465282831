/* eslint-disable no-empty */
/* eslint-disable no-underscore-dangle */
/**
 * Main application forms
 */
import "./style.css";
import MDBox from "components/Basics/MDBox";
import DashboardLayout from "components/Advanced/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Advanced/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ChartsActions from "redux-react/actions/chartsActions";
import FormActions from "redux-react/actions/formAction";
import {
	Badge,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Icon,
	IconButton,
	Menu,
	MenuItem
} from "@mui/material";
import i18n from "i18n";
import lod_ from "lodash";
import { useMaterialUIController } from "context";
import ChartsLoader from "components/Custom/ChartsLoader";
import DefaultDataTable from "components/Custom/Tables/DefaultDataTable";
import MDButton from "components/Basics/MDButton";
import { display } from "redux-react/reducers/snackBarReducer";
import { read, utils } from "xlsx";
import { parseFilters, getLocalStorageBackValues } from "components/Custom/Filters/filters";
import FormDictionaryDialog from "components/Custom/FormDictionary";
import ContactsActions from "redux-react/actions/contactsActions";
import MDTypography from "components/Basics/MDTypography";
import FusionContent from "./fusion/FusionContent";
import ImportDialog from "./import";

/**
 *
 * @param {Object} route
 *  - key : actual page
 *  - type : type of page
 *  - icon : icon to display in navbar
 *  - route : actual route
 * @returns
 */
export default function FormsPage({ route }) {
	const dispatch = useDispatch();
	const [addNewLabel, setAddNewLabel] = useState(false);
	const [valuesForm, setValuesForm] = useState({});
	const [formBuild, setFormBuild] = useState({});
	const [contextDictionary, setContextDictionary] = useState({});
	const [PI, setPI] = useState(false);
	const [empty, setEmpty] = useState(false);
	const [confirmDelete, setConfirmDelete] = useState(false);

	const { profile, filters } = useSelector(state => state);
	const [controller] = useMaterialUIController();
	const { darkMode } = controller;
	// Loader while charts are loading
	const [chartsLoading, setChartsLoading] = useState(false);
	// Page Charts
	const [charts, setCharts] = useState([]);
	// Filters configuration for the page
	const [pageFilters, setPageFilters] = useState([]);
	const [listAPIs, setlistAPIs] = useState([]);
	const [selectedItem, setSelectedItem] = useState({});
	const [selectedTarget, setSelectedTarget] = useState("");
	const [routeDict, setRouteDict] = useState("");
	const [flagAccount, setFlagAccount] = useState(false);
	const [regexEmail, setRegexEmail] = useState("");
	const [selectedId, setSelectedId] = useState(null);
	const [reloadTable, setReloadTable] = useState(false);
	// Datas for dialog
	const [importDialog, setImportDialog] = useState({ open: false });
	// File name when import CSV
	const [fileImportName, setFileImportName] = useState("");

	const [menuRef, setMenuRef] = useState(null);
	const [menuValues, setMenuValues] = useState({});

	const [fusionElements, setFusionElements] = useState({});

	const [fusionDialog, setFusionDialog] = useState(false);
	const [fusionResult, setFusionResult] = useState({});

	const [anchorElMenu, setAnchorElMenu] = useState(null);

	// Dictionary for dialog
	const [dictionary, setDictionary] = useState({});

	const canUseFusion = () => {
		let access = false;

		if (route.route === "/contact") {
			access = true;
		}

		return access;
	};

	/**
	 * Get charts data to be displayed in front
	 */
	function getChartsData(typeList) {
		return charts;
	}
	/**
	 * Get charts with filters from the back
	 */
	async function getCharts(requestFilters, pageFilters) {
		let mandatoryFilters = pageFilters.map(filter => filter.attribute);

		return new Promise((resolve, reject) => {
			dispatch(
				ChartsActions.getPageCharts(
					profile.assistantID,
					route.route,
					requestFilters,
					mandatoryFilters,
					route?.filterByAgent,
					res => resolve(res.charts)
				)
			);
		});
	}
	/**
	 * Get filters from back
	 */
	async function getPageFilters() {
		if (route.filter) {
			// If route has filter, get it
			return new Promise((resolve, reject) => {
				dispatch(
					ChartsActions.getPageFilters(profile.assistantID, route.filter, res => {
						resolve(res.filters);
					})
				);
			});
		} else {
			// return empty array
			return [];
		}
	}
	/**
	 * Load charts from back
	 */
	async function loadCharts() {
		// Get all filters from back
		let pageFilters = await getPageFilters();
		setPageFilters(pageFilters);
		// Get filters from local storage
		let actualFilters = getLocalStorageBackValues(
			profile.selectedAssistant.assistantID,
			route.route,
			filters
		);
		// Build charts with filters
		let chartsFromDatabase = await getCharts(actualFilters, pageFilters);
		setCharts(chartsFromDatabase);
		setDictionary(chartsFromDatabase[0].dictionary);
		setChartsLoading(false);
	}
	/**
	 * Load charts when assistant changes or route changes
	 */
	useEffect(() => {
		setChartsLoading(true);
		loadCharts();
	}, [profile.selectedAssistant.assistantID, route]);

	const actionEditHandle = (items, target, account) => {
		const onSuccess = res => {
			setAddNewLabel(true);
			setEmpty(false);
			setValuesForm(res.valueDictionary);
			setlistAPIs(res.APIs);
			setFormBuild(res.formBuilderDictionary);
			setContextDictionary(res.dictionary);
			setPI(false);
			setSelectedId(items._id);
			setRegexEmail(res.regexEmail);
			setSelectedTarget(target);
			setRouteDict(route.form.routeDictionary);
			setFlagAccount(account);
		};
		dispatch(
			FormActions.getItemByID(
				items._id,
				target,
				route.form.routeDictionary,
				onSuccess,
				false,
				account
			)
		);
	};

	const actionEditEmptyHandle = () => {
		const onSuccess = res => {
			setAddNewLabel(true);
			setEmpty(true);
			setValuesForm(res.valueDictionary);
			setlistAPIs(res.APIs);
			setRegexEmail(res.regexEmail);
			setFormBuild(res.formBuilderDictionary);
			setContextDictionary(res.dictionary);
			setPI(true);
			setSelectedTarget(route.form.collectionDefault);
			setRouteDict(route.form.routeDictionary);
		};

		dispatch(
			FormActions.getItemEmpty(route.form.collectionDefault, route.form.routeDictionary, onSuccess)
		);
	};

	const actionDeleteHandle = (item, target) => {
		setConfirmDelete(true);
		setSelectedItem(item);
		setSelectedTarget(target);
	};

	const closeDeleteHandle = () => {
		setConfirmDelete(false);
	};

	const deleteItem = () => {
		const onSuccess = res => {
			dispatch(
				display({
					message: i18n.t("FORMS.deleteSuccess"),
					type: "success"
				})
			);
			setReloadTable(!reloadTable);
			setConfirmDelete(false);
		};
		if (selectedItem._id) {
			dispatch(FormActions.deleteItem(selectedItem._id, selectedTarget, {}, onSuccess));
		} else {
			setConfirmDelete(false);
			dispatch(
				display({
					message: i18n.t("FORMS.deleteError"),
					type: "error"
				})
			);
		}
	};

	const actionSaveOrCreateItem = (values, unique, callback, rolesProfileUser) => {
		const onSuccess = res => {
			dispatch(
				display({
					message: i18n.t("FORMS.addEmpty"),
					type: "success"
				})
			);
			setReloadTable(!reloadTable);
			setAddNewLabel(false);
			callback(true);
		};
		let data = { values, target: selectedTarget, unique, isAccount: flagAccount };
		if (empty) {
			data = { ...data, rolesProfileUser };
			dispatch(
				FormActions.addItemEmpty(data, onSuccess, err => {
					callback(false);
				})
			);
		} else {
			dispatch(
				FormActions.updateItem(selectedId, data, onSuccess, err => {
					callback(false);
				})
			);
		}
	};
	// Handle import CSV
	const handleImportFile = event => {
		try {
			let file = event.target.files[0];
			const reader = new FileReader();

			reader.onload = e => {
				const wb = read(e.target.result);
				const sheets = wb.SheetNames;
				const data = utils.sheet_to_json(wb.Sheets[sheets[0]]);
				// Open import modal with data
				setImportDialog({ ...importDialog, open: true, values: data });
			};
			reader.readAsArrayBuffer(file);
			// Set file name
			setFileImportName(file.name);
			// Reset input
			event.target.value = null;
		} catch (error) {}
	};
	// Import CSV datas in DB
	const handleImportCSV = obj => {
		// Add supplementary datas
		obj.collection = route.form.collectionDefault;
		obj.assistantID = profile.assistantID;
		obj.fileName = fileImportName;

		const onSuccess = res => {
			dispatch(
				display({
					message: `${res.insertedCount} données importées`,
					type: "success"
				})
			);

			setReloadTable(!reloadTable);
		};

		dispatch(FormActions.importCSV(obj, onSuccess));
	};

	const [selectedRows, setSelectedRows] = useState([]);

	const createFusionContacts = () => {
		let data = {
			contacts: selectedRows.map(el => el._id)
		};

		dispatch(
			ContactsActions.createFusionContacts(profile.assistantID, data, ({ fusion }) => {
				setAnchorElMenu(null);
				setFusionElements(fusion);
				setFusionDialog(true);
			})
		);
	};

	/**
	 * Charts loader
	 */
	if (chartsLoading) {
		return (
			<DashboardLayout>
				<MDBox py={3}>
					<DashboardNavbar />
					<ChartsLoader darkMode={darkMode} />
				</MDBox>
			</DashboardLayout>
		);
	} else
	/**
	 * Main component
	 */
		return (
			<DashboardLayout>
				{!lod_.isEmpty(dictionary) && (
					<ImportDialog
						{...importDialog}
						dictionary={lod_.get(dictionary, route.form.routeDictionary)}
						handleClose={() => setImportDialog({ ...importDialog, open: false })}
						handleSave={handleImportCSV}
					/>
				)}
				<MDBox mb={3}>
					<DashboardNavbar
						filters={[
							<MDBox display="flex" justifyContent="space-between" alignItems="center">
								{/* First part */}
								<MDBox>
									<MDButton
										style={{ height: "100%", marginRight: "0.75rem" }}
										variant="contained"
										color="info"
										onClick={actionEditEmptyHandle}
									>
										<Icon>add</Icon>&nbsp;
										{i18n.t("FORMS.LABELS.add") + (route?.form?.pageLabel ?? route.name)}
									</MDButton>
									{route.form.importCSV === true && (
										<MDButton
											style={{ height: "100%", marginRight: "0.75rem" }}
											variant="contained"
											component="label"
											color="info"
											disabled={lod_.isEmpty(dictionary)}
										>
											<input
												type="file"
												name="file"
												accept={[
													".xls",
													".xlsx",
													".xlsm",
													".xlsb",
													".xlt",
													".xltx",
													".xltm",
													".xlam",
													".ods",
													".csv"
												]}
												hidden
												onChange={handleImportFile}
											></input>
											<Icon>publish</Icon>&nbsp;Importer un fichier
										</MDButton>
									)}
									{parseFilters(profile.assistantID, route.route, pageFilters, filters, dispatch)}
								</MDBox>
								{/* Second part */}
								<MDBox>
									{canUseFusion() && (
										<Badge
											badgeContent={selectedRows.length}
											color="error"
											anchorOrigin={{
												vertical: "top",
												horizontal: "right"
											}}
										>
											<MDButton
												variant="contained"
												color="dark"
												onClick={e => {
													setAnchorElMenu(e.currentTarget);
												}}
											>
												<Icon>list_alt</Icon>&nbsp;Actions contact
											</MDButton>
										</Badge>
									)}

									{/* Menu */}
									<Menu
										anchorEl={anchorElMenu}
										open={Boolean(anchorElMenu)}
										onClose={() => setAnchorElMenu(null)}
										anchorOrigin={{
											vertical: "bottom",
											horizontal: "center"
										}}
										transformOrigin={{
											vertical: "top",
											horizontal: "center"
										}}
									>
										{/* Actions */}
										<MenuItem
											disabled={selectedRows.length < 2}
											onClick={() => {
												createFusionContacts();
											}}
										>
											<Icon>people</Icon>&nbsp;{i18n.t("FORMS.FUSION.actionFusion")}
										</MenuItem>
									</Menu>
								</MDBox>
							</MDBox>
						]}
					/>
				</MDBox>

				{addNewLabel && (!lod_.isEmpty(valuesForm) || empty) && (
					<FormDictionaryDialog
						open={addNewLabel}
						route={route}
						handleCloseDialog={() => setAddNewLabel(false)}
						handleSave={(values, unique, callback) =>
							actionSaveOrCreateItem(values, unique, callback)
						}
						listAPIs={listAPIs}
						regexEmail={regexEmail}
						valuesDictionary={valuesForm}
						formBuildDictionary={formBuild}
						contextDictionary={contextDictionary}
						PIaccess={PI}
						isEmpty={empty}
						target={selectedTarget}
						selectedId={selectedId}
						routeDict={routeDict}
						flagAccount={flagAccount}
					/>
				)}
				{confirmDelete && (
					<Dialog open={confirmDelete} onClose={closeDeleteHandle}>
						<DialogTitle>{i18n.t("FORMS.LABELS.delete")}</DialogTitle>
						<DialogContent>
							{i18n.t("FORMS.LABELS.confirmDelete")}: {selectedItem.name}
						</DialogContent>
						<DialogActions>
							<MDButton autoFocus onClick={closeDeleteHandle} variant="outlined" color="dark">
								{i18n.t("FORMS.cancel")}
							</MDButton>
							<MDButton onClick={deleteItem} color="dark" variant="contained" autoFocus>
								{i18n.t("FORMS.validate")}
							</MDButton>
						</DialogActions>
					</Dialog>
				)}
				{/*
				 * Pagined table
				 */}
				{getChartsData()
					.filter(chart => chart.type === "paginedList")
					.map((chart, index) => {
						let chartFilters = getLocalStorageBackValues(
							profile.selectedAssistant.assistantID,
							route.route,
							filters
						);

						return (
							<MDBox>
								<DefaultDataTable
									key={index}
									form={route.form}
									dictionary={chart.dictionary}
									checkbox={canUseFusion()}
									selectedRows={selectedRows}
									handleSelection={row => {
										if (selectedRows.some(i => i._id === row._id)) {
											setSelectedRows(selectedRows.filter(item => item._id !== row._id));
										} else {
											setSelectedRows([...selectedRows, row]);
										}
									}}
									id={chart.code}
									canSearch
									table={chart.data}
									display={chart.request.attributesDisplay}
									pagination={chart.pagination}
									list={chart}
									reloadTable={reloadTable}
									filters={chartFilters}
									actions={[
										<IconButton
											handleclick={({ values }, e) => {
												setMenuValues(values);
												setMenuRef(e.currentTarget);
											}}
										>
											<Icon fontSize="medium">more_vert</Icon>
										</IconButton>
									]}
								/>

								{/* Menu actions selection */}
								<Menu
									open={Boolean(menuRef)}
									anchorEl={menuRef}
									onClose={() => {
										setMenuRef(null);
									}}
								>
									{/* Options */}
									<MenuItem disabled style={{ opacity: 1 }}>
										<MDTypography variant="caption">Edition</MDTypography>
									</MenuItem>
									<MenuItem
										onClick={() => {
											actionEditHandle(menuValues, chart.request.collection);
											setMenuRef(null);
										}}
									>
										<Icon fontSize="medium">edit</Icon>&nbsp;{i18n.t("SETTINGS.edit")}
									</MenuItem>
									<MenuItem
										onClick={() => {
											actionDeleteHandle(menuValues, chart.request.collection);
											setMenuRef(null);
										}}
									>
										<Icon fontSize="medium">delete</Icon>&nbsp;{i18n.t("SETTINGS.delete")}
									</MenuItem>
								</Menu>

								{/* Fusion Dialog */}
								<Dialog
									open={fusionDialog}
									onClose={() => setFusionDialog(false)}
									fullWidth
									PaperProps={{
										sx: {
											height: "100%",
											width: "100%",
											maxWidth: "100%"
										}
									}}
								>
									<DialogTitle>{i18n.t("FORMS.FUSION.titleActionFusion")}</DialogTitle>
									<DialogContent>
										<FusionContent
											dictionaryContact={chart.dictionary?.contact}
											fusionElements={fusionElements}
											onChange={finalContact => setFusionResult(finalContact)}
										/>
									</DialogContent>
									<DialogActions>
										<MDButton
											autoFocus
											onClick={() => {
												setFusionDialog(false);
												setFusionElements({});
												setSelectedRows([]);
											}}
											variant="outlined"
											color="info"
										>
											{i18n.t("FORMS.cancel")}
										</MDButton>
										<MDButton
											onClick={() => {
												let data = {
													newContact: fusionResult,
													oldContactsFRU: fusionElements.contacts.map(el => el.FRU)
												};

												dispatch(
													ContactsActions.validateFusionContacts(profile.assistantID, data, res => {
														dispatch(
															display({
																message: i18n.t("FORMS.FUSION.fusedContact"),
																type: "success"
															})
														);
														setFusionDialog(false);
														setSelectedRows([]);
														setReloadTable(!reloadTable);
													})
												);
											}}
											color="info"
											variant="contained"
											autoFocus
										>
											{i18n.t("FORMS.validate")}
										</MDButton>
									</DialogActions>
								</Dialog>
							</MDBox>
						);
					})}
			</DashboardLayout>
		);
}
