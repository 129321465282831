/**
 * Main application business page
 */
import "./style.css";
import MDBox from "components/Basics/MDBox";
import DashboardLayout from "components/Advanced/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Advanced/Navbars/DashboardNavbar";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { socket } from "redux-react/middleware/ws";
import ChartsActions from "redux-react/actions/chartsActions";
import FilesActions from "redux-react/actions/filesActions";
import FormAction from "redux-react/actions/formAction";
import { useMaterialUIController } from "context";
import ChartsLoader from "components/Custom/ChartsLoader";
import MDTypography from "components/Basics/MDTypography";
import { parseFilters, getLocalStorageBackValues } from "components/Custom/Filters/filters";
import DefaultDataTable from "components/Custom/Tables/DefaultDataTable";
import { Divider, Fade, Icon, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import MDButton from "components/Basics/MDButton";
import { display } from "redux-react/reducers/snackBarReducer";
import SoundPlayer from "components/Custom/SoundPlayer";
import { getUserPermissions } from "redux-react/reducers/permissionsReducer";
import PhoneCall from "./components/PhoneCall";

/**
 * Default component to display charts, works with dynamic datas
 */
export default function PhoneCallPage({ route }) {
	const dispatch = useDispatch();
	const { profile, filters } = useSelector(state => state);
	const permissions = useSelector(getUserPermissions);
	const [controller] = useMaterialUIController();
	const { darkMode } = controller;
	const rightPannelContainer = useRef(null);
	// Loader while charts are loading
	const [chartsLoading, setChartsLoading] = useState(false);
	// Page Charts
	const [charts, setCharts] = useState([]);
	// Filters configuration for the page
	const [pageFilters, setPageFilters] = useState([]);

	const [openPannel, setOpenPannel] = useState(false);
	const [phoneCall, setPhoneCall] = useState({});

	const [reloadTable, setReloadTable] = useState(false);

	const [anchorElMenu, setAnchorElMenu] = useState(null);

	const [fullScreen, setFullScreen] = useState(false);

	const [menuRef, setMenuRef] = useState(null);
	const [menuValues, setMenuValues] = useState({});

	// tab for right menu
	const [tab, setTab] = useState(0);
	// right menu loading
	const [visualiseConversationLoading, setVisualiseConversationLoading] = useState(true);

	const canExecActions = () => {
		return permissions.includes("exec:biovanciaActions");
	};

	/**
	 * Get charts data to be displayed in front
	 */
	function getChartsData(typeList) {
		return charts.filter(chart => typeList.includes(chart.type));
	}
	/**
	 * Get charts with filters from the back
	 */
	async function getCharts(requestFilters, pageFilters) {
		let mandatoryFilters = pageFilters.map(filter => filter.attribute);

		return new Promise((resolve, reject) => {
			dispatch(
				ChartsActions.getPageCharts(
					profile.assistantID,
					route.route,
					requestFilters,
					mandatoryFilters,
					null,
					res => resolve(res.charts)
				)
			);
		});
	}
	/**
	 * Get filters from back
	 */
	async function getPageFilters() {
		if (route.filter) {
			// If route has filter, get it
			return new Promise((resolve, reject) => {
				dispatch(
					ChartsActions.getPageFilters(profile.assistantID, route.filter, res => {
						resolve(res.filters);
					})
				);
			});
		} else {
			// return empty array
			return [];
		}
	}
	/**
	 * Load charts from back
	 */
	async function loadCharts() {
		// Get all filters from back
		let pageFilters = await getPageFilters();
		setPageFilters(pageFilters);
		// Get filters from local storage
		let actualFilters = getLocalStorageBackValues(
			profile.selectedAssistant.assistantID,
			route.route,
			filters
		);
		// Build charts with filters
		let chartsFromDatabase = await getCharts(actualFilters, pageFilters);
		setCharts(chartsFromDatabase);
		setChartsLoading(false);
	}
	/**
	 * Load charts when assistant changes or route changes
	 */
	useEffect(() => {
		setChartsLoading(true);
		loadCharts();
	}, [profile.selectedAssistant.assistantID, route]);

	const transcriptAllFiles = () => {
		dispatch(
			display({
				message: `Transcription des fichiers en cours`,
				type: "info"
			})
		);
		dispatch(
			FilesActions.transcriptAllFiles(profile.assistantID, res => {
				setReloadTable(!reloadTable);
				dispatch(
					display({
						message: `Les fichiers ont été transcrits avec succès`,
						type: "success"
					})
				);
			})
		);
	};

	const categoryzeAllFiles = () => {
		dispatch(
			display({
				message: `Catégorisation des fichiers en cours`,
				type: "info"
			})
		);
		dispatch(
			FilesActions.categoryzeAllFiles(profile.assistantID, res => {
				setReloadTable(!reloadTable);
				dispatch(
					display({
						message: `Les fichiers ont été catégorisés avec succès`,
						type: "success"
					})
				);
			})
		);
	};

	const cleanAllFiles = () => {
		dispatch(
			display({
				message: `Nettoyage des fichiers en cours`,
				type: "info"
			})
		);
		dispatch(
			FilesActions.cleanAllFiles(profile.assistantID, res => {
				setReloadTable(!reloadTable);
				dispatch(
					display({
						message: `Les fichiers ont été nettoyés avec succès`,
						type: "success"
					})
				);
			})
		);
	};

	const analyseAllFiles = () => {
		dispatch(
			display({
				message: `Analyse des fichiers en cours`,
				type: "info"
			})
		);
		dispatch(
			FilesActions.analyzeAllFiles(profile.assistantID, res => {
				setReloadTable(!reloadTable);
				dispatch(
					display({
						message: `Les fichiers ont été analysés avec succès`,
						type: "success"
					})
				);
			})
		);
	};

	const loadFiles = () => {
		dispatch(
			display({
				message: `Chargement des fichiers en cours`,
				type: "info"
			})
		);
		dispatch(
			FilesActions.loadFiles(profile.assistantID, res => {
				setReloadTable(!reloadTable);
				dispatch(
					display({
						message: `Les fichiers ont été chargés avec succès`,
						type: "success"
					})
				);
			})
		);
	};

	const transcriptFile = code => {
		dispatch(
			display({
				message: `Transcription du fichier en cours`,
				type: "info"
			})
		);
		dispatch(
			FilesActions.transcriptFile(profile.assistantID, code, res => {
				setReloadTable(!reloadTable);
				// dispatch(
				// 	display({
				// 		message: `Le fichier a été transcrit avec succès`,
				// 		type: "success"
				// 	})
				// );
			})
		);
	};

	const cleanFile = code => {
		dispatch(
			display({
				message: `Nettoyage du fichier en cours`,
				type: "info"
			})
		);
		dispatch(
			FilesActions.cleanFile(profile.assistantID, code, res => {
				setReloadTable(!reloadTable);
				dispatch(
					display({
						message: `Le fichier a été nettoyé avec succès`,
						type: "success"
					})
				);
			})
		);
	};

	const analyseFile = code => {
		dispatch(
			display({
				message: `Analyse de la conversation en cours`,
				type: "info"
			})
		);
		dispatch(
			FilesActions.analyzeFile(profile.assistantID, code, res => {
				setPhoneCall(res.conv || {});
				setReloadTable(!reloadTable);
				dispatch(
					display({
						message: `Le fichier a été analysé avec succès`,
						type: "success"
					})
				);
			})
		);
	};

	const customerRework = code => {
		dispatch(
			display({
				message: `Rework du client en cours`,
				type: "info"
			})
		);
		dispatch(
			FilesActions.customerRework(profile.assistantID, code, res => {
				if (res.conv) {
					setPhoneCall(res.conv);
				}
				setReloadTable(!reloadTable);
				dispatch(
					display({
						message: `Le client a été reworké avec succès`,
						type: "success"
					})
				);
			})
		);
	};

	const analyzeProducts = code => {
		dispatch(
			display({
				message: `Recherche de produits en cours`,
				type: "info"
			})
		);
		dispatch(
			FilesActions.analyzeProducts(profile.assistantID, code, res => {
				setPhoneCall(res?.conv || {});
				setReloadTable(!reloadTable);
				dispatch(
					display({
						message: `La recherche de produits a été effectuée avec succès`,
						type: "success"
					})
				);
			})
		);
	};

	const analyzeActions = code => {
		dispatch(
			display({
				message: `Analyse des actions en cours`,
				type: "info"
			})
		);
		dispatch(
			FilesActions.analyzeActions(profile.assistantID, code, res => {
				setPhoneCall(res?.conv || {});
				setReloadTable(!reloadTable);
				dispatch(
					display({
						message: `Les actions ont été analysées avec succès`,
						type: "success"
					})
				);
			})
		);
	};

	const closePannel = () => {
		setOpenPannel(false);
		setFullScreen(false);
		setPhoneCall({});
	};

	function listenPhoneCall(code) {
		dispatch(
			FilesActions.listenPhoneCall(profile.assistantID, code, res => {
				if (res.token) {
					setPhoneCall(prev => {
						return {
							...prev,
							token: res.token
						};
					});
				}
			})
		);
	}

	const visualiseConversation = (fID = menuValues.fID) => {
		setVisualiseConversationLoading(true);
		setPhoneCall({
			fID
		});
		setOpenPannel(true);
		dispatch(
			FormAction.getItemsFromCollection(
				"convStat",
				{
					query: {
						fID,
						active: { $in: [true, false, null] }
					}
				},
				res => {
					let conversation = res.items[0];
					if (!conversation) {
						setOpenPannel(false);
						setVisualiseConversationLoading(false);
						return;
					}

					setVisualiseConversationLoading(false);
					setPhoneCall(conversation);

					listenPhoneCall(conversation.fID);

					// if scroll in page is above rightPannelContainer ref, scroll to it
					setTimeout(() => {
						let scrollPosition = document.documentElement.scrollTop;
						let pannelTopPosiiton = rightPannelContainer.current.offsetTop;

						if (scrollPosition < pannelTopPosiiton) {
							rightPannelContainer.current.scrollIntoView({
								behavior: "smooth",
								block: "start"
							});
						}
					}, 200);
				}
			)
		);
	};

	const updatePhoneCall = ({ conversation }) => {
		if (phoneCall.fID !== conversation.fID) {
			return;
		}
		setPhoneCall(conversation);
	};

	useEffect(() => {
		socket.on("update_conversation_phone_call", updatePhoneCall);
		return () => {
			socket.off("update_conversation_phone_call", updatePhoneCall);
		};
	}, []);

	/* Charts loader */
	if (chartsLoading) {
		return (
			<DashboardLayout>
				<MDBox py={3}>
					<DashboardNavbar />
					<ChartsLoader darkMode={darkMode} />
				</MDBox>
			</DashboardLayout>
		);
	} else
	/* Main component */
		return (
			<DashboardLayout>
				<MDBox py={3}>
					{pageFilters && (
						<DashboardNavbar
							filters={[
								<MDBox
									display="flex"
									justifyContent="space-between"
									style={{
										width: "100%"
									}}
								>
									{/* First container */}
									<MDBox display="flex">
										{parseFilters(profile.assistantID, route.route, pageFilters, filters, dispatch)}
									</MDBox>
									{/* Second container */}
									{canExecActions() && (
										<MDButton
											variant="contained"
											color="dark"
											onClick={e => {
												setAnchorElMenu(e.currentTarget);
											}}
										>
											<Icon>list_alt</Icon>&nbsp;Actions fichiers
										</MDButton>
									)}
									{/* Menu */}
									<Menu
										anchorEl={anchorElMenu}
										open={Boolean(anchorElMenu)}
										onClose={() => setAnchorElMenu(null)}
										anchorOrigin={{
											vertical: "bottom",
											horizontal: "center"
										}}
										transformOrigin={{
											vertical: "top",
											horizontal: "center"
										}}
									>
										{/* Actions */}
										<MenuItem
											onClick={() => {
												setAnchorElMenu(null);
												loadFiles();
											}}
										>
											<Icon>download</Icon>&nbsp;Charger tous les fichiers
										</MenuItem>
										<MenuItem
											onClick={() => {
												setAnchorElMenu(null);
												transcriptAllFiles();
											}}
										>
											<Icon>record_voice_over</Icon>&nbsp;Transcrire tous les fichiers
										</MenuItem>
										<MenuItem
											onClick={() => {
												setAnchorElMenu(null);
												cleanAllFiles();
											}}
										>
											<Icon>cleaning_services_icon</Icon>&nbsp;Nettoyer tous les fichiers
										</MenuItem>
										<MenuItem
											onClick={() => {
												setAnchorElMenu(null);
												analyseAllFiles();
											}}
										>
											<Icon>memory</Icon>&nbsp;Analyser tous les fichiers
										</MenuItem>
										<MenuItem
											onClick={() => {
												setAnchorElMenu(null);
												categoryzeAllFiles();
											}}
										>
											<Icon>class</Icon>&nbsp;Catégoriser tous les fichiers
										</MenuItem>
									</Menu>
								</MDBox>
							]}
						/>
					)}
				</MDBox>

				{/* Pagined table */}
				{getChartsData(["paginedList"]).map((chart, index) => {
					return (
						<MDBox
							key={index}
							className="pageContentContainer"
							style={{
								display: openPannel ? "flex" : "block"
							}}
						>
							{!fullScreen && (
								<MDBox flex="1">
									<DefaultDataTable
										handleSelection={row => {
											if (phoneCall?.fID !== row.fID) {
												setMenuValues(row);
												visualiseConversation(row.fID);
											}
										}}
										selectedRow={values => {
											return values.fID === phoneCall.fID;
										}}
										dictionary={chart.dictionary}
										reloadTable={reloadTable}
										id={chart.code}
										list={chart}
										pagination={chart.pagination}
										canSearch
										table={chart.data}
										display={chart.request.attributesDisplay}
										filters={getLocalStorageBackValues(profile.assistantID, route.route, filters)}
										actions={[
											<IconButton
												handleclick={({ values }, e) => {
													setMenuValues(values);
													setMenuRef(e.currentTarget);
													e.stopPropagation();
												}}
											>
												<Icon fontSize="medium">more_vert</Icon>
											</IconButton>
										]}
									/>
								</MDBox>
							)}
							{openPannel && phoneCall && (
								<MDBox
									className={fullScreen ? "rightPannelContainerFullScreen" : "rightPannelContainer"}
									ref={rightPannelContainer}
								>
									<PhoneCall
										tab={tab}
										setTab={setTab}
										phoneCall={phoneCall}
										closePannel={closePannel}
										visualiseConversationLoading={visualiseConversationLoading}
										fullScreen={fullScreen}
										setFullScreen={setFullScreen}
									/>
								</MDBox>
							)}
						</MDBox>
					);
				})}

				{/* Menu actions selection */}
				<Menu
					open={Boolean(menuRef)}
					anchorEl={menuRef}
					onClose={() => {
						setMenuRef(null);
					}}
				>
					{/*  */}
					{canExecActions() && (
						<div>
							<MenuItem disabled style={{ opacity: 1 }}>
								<MDTypography variant="caption">Actions principales</MDTypography>
							</MenuItem>
							<MenuItem
								disabled={menuValues.state !== "uploaded"}
								onClick={() => {
									transcriptFile(menuValues.code);
									setMenuRef(null);
								}}
							>
								<Icon fontSize="medium">record_voice_over</Icon>&nbsp;Transcrire le fichier
							</MenuItem>
							<MenuItem
								disabled={!["transcripted", "cleaned", "analyzed"].includes(menuValues.state)}
								onClick={() => {
									cleanFile(menuValues.code);
									setMenuRef(null);
								}}
							>
								<Icon fontSize="medium">cleaning_services_icon</Icon>&nbsp;Nettoyer le fichier
							</MenuItem>
							<MenuItem
								disabled={!["cleaned", "analyzed"].includes(menuValues.state)}
								onClick={() => {
									analyseFile(menuValues.code);
									setMenuRef(null);
								}}
							>
								<Icon fontSize="medium">memory</Icon>&nbsp;Analyser le fichier
							</MenuItem>
							{/*  */}
							<Divider />
							<MenuItem disabled style={{ opacity: 1 }}>
								<MDTypography variant="caption">Actions secondaires</MDTypography>
							</MenuItem>
							<MenuItem
								disabled={!["analyzed"].includes(menuValues.state)}
								onClick={() => {
									customerRework(menuValues.code);
									setMenuRef(null);
								}}
							>
								<Icon fontSize="medium">face</Icon>&nbsp;Informations client
							</MenuItem>
							<MenuItem
								disabled={!["analyzed"].includes(menuValues.state)}
								onClick={() => {
									analyzeActions(menuValues.code);
									setMenuRef(null);
								}}
							>
								<Icon fontSize="medium">list</Icon>&nbsp;Analyser les actions
							</MenuItem>
							<MenuItem
								disabled={!["analyzed"].includes(menuValues.state)}
								onClick={() => {
									analyzeProducts(menuValues.code);
									setMenuRef(null);
								}}
							>
								<Icon fontSize="medium">shopping_cart</Icon>&nbsp;Recherche de produits
							</MenuItem>
							<Divider />
						</div>
					)}
					{/* Chat */}
					<MenuItem disabled style={{ opacity: 1 }}>
						<MDTypography variant="caption">Conversation</MDTypography>
					</MenuItem>
					<MenuItem
						// disabled={menuValues.dialog === undefined}
						onClick={() => {
							visualiseConversation();
							setMenuRef(null);
						}}
					>
						<Icon fontSize="medium">chat</Icon>&nbsp;Voir la discussion
					</MenuItem>
				</Menu>

				<MDBox mt={10}></MDBox>

				{/* Floating button */}
				<Fade in={Boolean(phoneCall.token)}>
					<div
						style={{
							position: "fixed",
							bottom: 20,
							right: "50%",
							transform: "translateX(50%)",
							zIndex: 1000
						}}
					>
						<SoundPlayer src={phoneCall.token} />
					</div>
				</Fade>
			</DashboardLayout>
		);
}
